import { ManifestClient } from './manifest';

let initializedResolve;
let initialized = new Promise((resolve) => {
    initializedResolve = resolve;
})

export const Environments = {
    STAGING: 'staging',
    PRODUCTION: 'production'
}

export const Events = {
    MANIFEST_UPDATE: 'mi-manifest-update'
}

export const RefreshEventReasons = {
    USER_REQUESTED: 'user requested',
    DATA_EXPIRED: 'data expired',
    INTERNAL_REFRESH: 'internal refresh',
}

export class Configuration {
    static getConfiguration(env) {
        if(env === Environments.STAGING) {
            return {
                baseUri: 'https://staging.meltwater.net/manifest',
                cookieDomain: '.meltwater.net'
            }
        } else if (env === Environments.PRODUCTION) {
            return {
                baseUri: 'https://app.meltwater.com/manifest',
                cookieDomain: '.meltwater.com'
            }
        } else {
            throw new Error(`Configuration - unknown environment provided. Provided Value ${env}`);
        }
    }
}

export class Initializer {
    static initialize(env, payload) {
        new ManifestClient(Configuration.getConfiguration(env), payload);
        initializedResolve();
    }
    static onInitialized() {
        return initialized;
    }
}